import { MessagesList } from "pages/Cockpit/Cockpit";
import React from "react";
import { useSelector } from "react-redux";
import { transformLightToHeavyMessage } from "./function";

const MessageItemCOLDFaibrikLight = () => {
	const { selectedConversation } = useSelector(state => state.cockpit);

	// Map message light to heavy to reuse display MessageItem and remove null element that couldn't be converted
	const messages = selectedConversation.payload.messages
		.map(transformLightToHeavyMessage)
		.filter(message => message);

	return (
		<MessagesList
			messages={messages}
			displayForwardedMessages={false}
			selectedConversation={selectedConversation}
			convID={selectedConversation._id}
			setSnackbarStatus={() => {}}
			handleEngagedialog={false}
			messagesListScrollRef={false}
			showMessageStateInfo={false}
			showMessageActions={false}
		/>
	);
};

export default MessageItemCOLDFaibrikLight;
