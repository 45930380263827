// Define some colors for the messages
const WHITE_COLOR = "#fff";
const GRAY_COLOR = "#efefef";
const MID_GRAY_COLOR = "#ddd";
const DARK_GRAY_COLOR = "#c2c0c0";
const ORANGE_COLOR = "#f8a933bd";
const LIGHT_GRAY_BLUE_COLOR = "#a8b4b7";
const LIGHT_BLUE_COLOR = "#D8EBF9";
const POST_IT_COLOR = "#FFF8CC";

module.exports = {
	getMessageStyle
};

/**
 * Get the paper style for the message depending on the message type
 * @param {Object} message - The message object from the conversation.messages
 * @returns {Object} - The css style object for the message paper
 */
function getMessageStyle(message) {
	try {
		let paperStyle = {
			width: message.header?.channel?.type == "AV" ? "100%" : "auto" // Doubt this is usefull, see variable fullWidthDisplay in the same file
		};
		let isLeftDirection = true;

		const isCockpitMessage = message.header.source === "cockpit";
		const isBotMessage = message.header.source === "bot";
		const isContactMessage = message.header.source === "contact";

		const isResolvedMessage = message.header?.state === "resolved" && isContactMessage;
		const isExternalMessage = message.header.source === "external";

		const isOperatorMessageOldWayMirakl =
			message?.header?.extra?.senderType?.toUpperCase() === "OPERATOR" && isContactMessage;
		const isOperatorMessageNewWay = message.header.source === "operator";

		const isMailbotackBot = message.header?.subtype === "mailbotackBot";

		const isOperatorMessage = isOperatorMessageOldWayMirakl || isOperatorMessageNewWay;

		const isForwardedMessage = message.header.type === "forwarded";

		const isNoteMessage = message.header.type === "note";

		let backgroundColor, headerBackgroundColor;

		if (isResolvedMessage) {
			// Display in gray the resolved message from the contact
			backgroundColor = GRAY_COLOR;
			isLeftDirection = true;
		} else if (isExternalMessage) {
			// Display in orange the external message, which are echo. Message sent by our client to the contact (client's client), but not via our platform
			backgroundColor = ORANGE_COLOR;
			isLeftDirection = false;
		} else if (isOperatorMessage) {
			// Display in gray blue the operator message, which are message sent by the intermediate between our client and the contact (client's client). It could be the marketplace
			backgroundColor = LIGHT_GRAY_BLUE_COLOR;
			isLeftDirection = true;
		} else if (message.header.externalSender) {
			// Display in lavender to signify exterior to the conversation
			backgroundColor = MID_GRAY_COLOR;
			headerBackgroundColor = DARK_GRAY_COLOR;
			isLeftDirection = true; // Assuming it's from the exterior contact
		} else if (isCockpitMessage || isBotMessage) {
			// Display in white and on right
			backgroundColor = WHITE_COLOR;
			isLeftDirection = false;
		} else if (isContactMessage) {
			// Display in white and on left
			backgroundColor = WHITE_COLOR;
			isLeftDirection = true;
		}

		if (isMailbotackBot) {
			backgroundColor = DARK_GRAY_COLOR;
		}

		if (isForwardedMessage) {
			backgroundColor = LIGHT_BLUE_COLOR;
		}

		if (isNoteMessage) {
			// Display in post-it color
			backgroundColor = POST_IT_COLOR;
			paperStyle.flex = 1;
		}

		paperStyle.backgroundColor = backgroundColor;
		paperStyle.headerBackgroundColor = headerBackgroundColor;

		const justifyDirection = isLeftDirection ? "flex-start" : "flex-end";

		return { paperStyle, justifyDirection };
	} catch (error) {
		// We don't want to block the code here, still we want to know the error in dev
		if (!process.env.REACT_APP_ENV.includes("prod")) {
			console.error(`getMessageStyle - Unexpected error ${error.message}`);
		}
		return { paperStyle: {}, justifyDirection: "flex-start" };
	}
}
