/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, List, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { memo, Suspense, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCockpitSelector } from "redux/selectors/cockpitSelectors.js";
import C from "../../constants/cockpit.js";
import ChannelUserInfoMembershipDisplayer from "../../helpers/ChannelUserInfoMembershipDisplayer";
import HistoricList from "../../helpers/HistoricList";
import cockpitActions from "../../redux/actions/cockpitActions";
import engageActions from "../../redux/actions/engageActions";
import {
	formatDate,
	handleSpecialDisplayDate,
	getTabs,
	isConversationLive
} from "./../../helpers/utilities";
import CockpitTabInfo from "./CockpitTabInfo/CockpitTabInfo";
import ConvsContainer from "./Convs/ConvsContainer";
import EditMessageItem from "./EditMessageItem";
import HeaderConvItem from "./HeaderConvItem";
import MessageItem from "./Message/MessageItem.js";
import i18n from "i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarStore from "./SnackbarStore";
import SnackBarContainer from "./SnackbarStore";
import HeaderConvItemCOLD from "./Convs/cold/HeaderConvItemCOLD.js";
import MessageItemCOLD from "./Convs/cold/MessageItemCOLD.js";
import ContextCallinterface from "./Convs/call/CallInterface.js";
import { wsEvent } from "redux/actions/ws.js";
import { isLiveChatEnabled } from "helpers/utilities";
import NotificationFavicon from "../../components/Notification/NotificationFavicon.js";
import { getTsOfMessage } from "helpers/messageUtils.js";
import Summary from "helpers/Summary.js";
import lod_ from "lodash";

const Case = React.lazy(() => import("./MiddleComponent/Case.js"));

export const MessagesList = ({
	convID,
	messages,
	setSnackbarStatus,
	handleEngagedialog,
	messagesListScrollRef,
	displayForwardedMessages,
	showMessageStateInfo = true,
	showMessageActions = true
}) => {
	let cockpitSettings = useSelector(state => state?.userStatus?.auth?.user?.cockpitSettings);

	if (cockpitSettings?.forceMessageSortNewerToOlder) {
		messages.sort((a, b) => {
			return getTsOfMessage(b) - getTsOfMessage(a);
		});
	}

	return messages.map((message, index) => {
		if (message?.header?.draft !== true) {
			return (
				<MessageItem
					key={`${convID} -${index} `}
					message={message}
					displayForwardedMessages={displayForwardedMessages}
					setSnackbarStatus={setSnackbarStatus}
					msgIndex={index}
					handleEngagedialog={handleEngagedialog}
					messagesListScrollRef={messagesListScrollRef}
					showMessageStateInfo={showMessageStateInfo}
					showMessageActions={showMessageActions}
				/>
			);
		}
	});
};

function Cockpit() {
	const dispatch = useDispatch();
	const {
		selectedConversation,
		convHistory,
		leftTab,
		attachments,
		convLoading,
		userStatus,
		isCockpitArchived,
		isCold,
		assistantConfig,
		selectedAssistantID,
		isConvListMinimized
	} = useSelector(getCockpitSelector, shallowEqual);
	let cockpitSettings = useSelector(state => state?.userStatus?.auth?.user?.cockpitSettings);
	const [snackbarStatus, setSnackbarStatus] = useState({ open: false, message: "" }); //to remove to use snackbarStore instead
	const [openRightMenu, setOpenRightMenu] = useState(false);
	const messagesListRef = useRef(null);
	const messagesTopListRef = useRef(null);
	const messagesListScrollRef = useRef(null);
	const historicListRef = useRef(null);
	const [user, setUser] = useState({});
	const [displayForwardedMessages, setDisplayForwardedMessages] = useState(true);
	const [displayHistory, setDisplayHistory] = useState(false);
	const [displaySummary, setDisplaySummary] = useState(false);
	const [historyBACK, setHistoryBACK] = useState([]);
	const [convHistoryFromBack, setConvHistoryFromBack] = useState([]);
	const editMessageRef = useRef();

	const [indexMsg, setIndexMsg] = useState("");

	const handleDisplayForwardedMessages = () => {
		setDisplayForwardedMessages(!displayForwardedMessages);
	};

	const goToLastMessage = () => {
		if (cockpitSettings?.forceMessageSortNewerToOlder) {
			scrollMessagesToTop();
		} else {
			scollMessagesToBottom();
		}
	};

	const handleOpenRightMenu = event => {
		setOpenRightMenu(!openRightMenu);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ open: false, message: "" });
	};

	const scrollMessagesToTop = () => {
		if (displayHistory) {
			if (historicListRef.current) {
				historicListRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
					inline: "nearest"
				});
			}
		} else {
			messagesTopListRef?.current?.scrollIntoView();
		}
	};

	const scollMessagesToBottom = () => {
		if (messagesListRef.current) {
			messagesListRef.current.scrollIntoView();
		}
	};

	const handleEngagedialog = async (
		indexMsg,
		successEngage,
		channel,
		initialDraft,
		originFID,
		originMID
	) => {
		//new engage
		const channelCode = channel?.code;
		if (channelCode) {
			dispatch(
				engageActions.createEngageConversation(
					channelCode,
					initialDraft,
					originFID,
					originMID,
					channel
				)
			);
		}
	};

	const cockpitSizeOpenedMenu = selectedConversation?.header.type !== "call" ? 6 : 5;

	// Scroll to bottom / top when page is loaded, depend of settings
	// update only when selectedConversation change or message ref change
	useEffect(() => {
		if (displayHistory) return;
		// setTimeout here to wait for the DOM to be updated
		setTimeout(() => {
			goToLastMessage();
		}, 0);
	}, [selectedConversation?.header?.fID, messagesListRef.current]);

	useEffect(() => {
		if (displayHistory) {
			scrollMessagesToTop();
		} else {
			goToLastMessage();
		}
	}, [displayHistory]);

	useEffect(() => {
		if (userStatus?.auth) {
			setUser({
				_id: userStatus.auth.user._id,
				name: userStatus.auth.user.name,
				role: userStatus.auth.user.role
			});
		}
	}, [userStatus]);

	/**
	 * Recompute humanRequest on tab and selectedAssistant update
	 */
	useEffect(() => {
		if (user?.email && selectedAssistantID)
			if (isLiveChatEnabled(assistantConfig?.channels))
				dispatch(
					wsEvent({
						action: "computeHumanRequestAgent",
						assistantID: selectedAssistantID,
						email: user.email,
						groups: user.groups
					})
				);
	}, [leftTab, selectedAssistantID, user?.email, assistantConfig]);

	useEffect(() => {
		if (
			!lod_.isEmpty(assistantConfig) &&
			assistantConfig?.cockpit?.displayDefaultSummary === true
		) {
			setDisplaySummary(true);
		}
	}, [assistantConfig]);

	//Make sur that lefTab is in correct position after changing selectedConversation
	useEffect(() => {
		if (selectedConversation) {
			const state = selectedConversation.header.state;
			const tabs = getTabs();
			//get tab by conversation's state
			let newTab = Object.keys(tabs).find(key => tabs[key] === state) || 0;
			if (
				!isCockpitArchived &&
				["waiting", "ongoing"].includes(state) &&
				isConversationLive(selectedConversation.header)
			)
				//if it is a live channel (in "live" tab) get live index
				newTab = Object.keys(getTabs()).find(key => tabs[key] === "live");

			newTab = parseInt(newTab);

			if (newTab !== leftTab && !isCold) dispatch(cockpitActions.setLeftTab(newTab));
		}
		if (selectedConversation?.header.type === "call") {
			setOpenRightMenu(true);
		}
		if (selectedConversation?.header.type === "case") {
			setOpenRightMenu(false);
		}
		setIndexMsg("");
		dispatch({ type: C.SET_FORCE_SELECT_CONVERSATION, payload: false });

		// eslint-disable-next-line
	}, [selectedConversation && selectedConversation?.header?.fID]);
	/**
	 * Get history for selectedConversation && handle display history
	 */
	useEffect(() => {
		if (selectedConversation) {
			let HistoryFromBack = convHistory?.filter(
				conv => conv.header.fID !== selectedConversation.header.fID
			);

			let newHistoryFromBackEnriched =
				HistoryFromBack &&
				HistoryFromBack.map(function (n) {
					return {
						id: n._id,
						meta: n.meta,
						header: n.header,
						Notes: n.notes,
						messages: n.messages,
						agent: n.agent
					};
				});

			setConvHistoryFromBack(HistoryFromBack);
			setHistoryBACK(newHistoryFromBackEnriched && newHistoryFromBackEnriched);
		} else setHistoryBACK(null);
	}, [convHistory, selectedConversation]);

	// phoneDisplay is when the context/contact is central. When we have some messages "transcript" or "recording" we don't display the phone interface but the regular one with messages
	const phoneDisplay =
		selectedConversation?.header?.type === "call" &&
		!selectedConversation?.messages?.some(message =>
			["transcript", "recording"].includes(message?.payload?.header?.context?.status)
		);
	const handleChangeDisplaySummary = () => {
		setDisplaySummary(!displaySummary);
	};

	return (
		<Paper style={{ height: "97vh" }} data-component-name="Cockpit">
			<SnackBarContainer />
			<Grid container style={{ height: "100%", flexWrap: "nowrap" }}>
				{!isConvListMinimized && <ConvsContainer user={user} />}

				<Grid
					container
					item
					xs={selectedConversation && openRightMenu ? cockpitSizeOpenedMenu : 9}
					style={{ maxWidth: "100%", flex: 1, height: "100%", width: "50%" }}
				>
					{convLoading && <CircularProgress style={{ position: "absolute", zIndex: "1" }} />}
					{selectedConversation && (
						<Box
							width="100%"
							height="100%"
							border={1}
							borderColor="#7E7D7D"
							borderTop={0}
							borderBottom={0}
						>
							{selectedConversation.header?.type === "case" ? (
								<Suspense fallback={<CircularProgress />}>
									<Case
										conversationInfo={selectedConversation}
										assistantConfig={assistantConfig.dictionary.context.items}
										configContact={assistantConfig.dictionary.contact.items}
									/>
								</Suspense>
							) : (
								<Box
									width="100%"
									height="97vh"
									display="flex"
									flexDirection="column"
									border={1}
									borderColor="#7E7D7D"
									borderTop={0}
									borderBottom={0}
								>
									{isCold ? (
										<HeaderConvItemCOLD
											convHistory={convHistory}
											historyBACK={historyBACK}
											displayHistory={displayHistory}
											setDisplayHistory={setDisplayHistory}
											setSnackbarStatus={setSnackbarStatus}
											handleOpenRightMenu={handleOpenRightMenu}
										/>
									) : (
										<HeaderConvItem
											leftTab={leftTab}
											convHistory={convHistory}
											historyBACK={historyBACK}
											displayHistory={displayHistory}
											displaySummary={displaySummary}
											handleChangeDisplaySummary={handleChangeDisplaySummary}
											handleDisplayForwardedMessages={handleDisplayForwardedMessages}
											setDisplayHistory={setDisplayHistory}
											setSnackbarStatus={setSnackbarStatus}
											handleOpenRightMenu={handleOpenRightMenu}
											convHistoryFromBack={convHistoryFromBack}
										/>
									)}
									<Box display="flex" flexDirection="column" flex={1} overflow="auto">
										<Box ref={messagesListScrollRef} flex={1} overflow="auto" bgcolor="#E7E7E7">
											{displaySummary && selectedConversation?.meta?.cockpit?.summary && (
												<Box
													style={{
														position: "sticky",
														top: 0,
														zIndex: 1
													}}
												>
													<Summary conversation={selectedConversation} />
												</Box>
											)}

											{phoneDisplay ? (
												<ContextCallinterface callHistory={false} />
											) : isCold ? (
												<MessageItemCOLD />
											) : (
												<List>
													<div ref={historicListRef}></div>

													{historyBACK &&
														historyBACK.length !== 0 &&
														displayHistory === true &&
														historyBACK.map((element, index) => (
															<HistoricList
																t={i18n.t}
																conversation={element}
																agent={selectedConversation?.agent}
																number={index}
																key={`historicList${index}`}
																history={convHistoryFromBack}
																ChannelUserInfoMembershipDisplayer={
																	ChannelUserInfoMembershipDisplayer
																}
																formatDate={formatDate}
																handleSpecialDisplayDate={handleSpecialDisplayDate}
															/>
														))}

													<div ref={messagesTopListRef}></div>
													{selectedConversation && selectedConversation.messages && (
														<MessagesList
															messages={selectedConversation.messages}
															displayForwardedMessages={displayForwardedMessages}
															selectedConversation={selectedConversation}
															convID={selectedConversation._id}
															setSnackbarStatus={setSnackbarStatus}
															handleEngagedialog={handleEngagedialog}
															messagesListScrollRef={messagesListScrollRef}
														/>
													)}
													<div ref={messagesListRef}> </div>
												</List>
											)}
										</Box>
										{(selectedConversation?.header?.state === "ongoing" ||
											selectedConversation?.header?.state === "resolved") &&
										selectedConversation.agent.uid === user._id &&
										selectedConversation?.header?.type !== "call" ? (
											<Box overflow={"hidden"}>
												<EditMessageItem ref={editMessageRef} indexMsg={indexMsg} />
											</Box>
										) : (
											<></>
										)}
									</Box>
								</Box>
							)}
						</Box>
					)}
				</Grid>
				{selectedConversation && openRightMenu && (
					<CockpitTabInfo
						xs={9 - cockpitSizeOpenedMenu}
						setSnackbarStatus={setSnackbarStatus}
						history={convHistoryFromBack}
						historyBACK={historyBACK}
					/>
				)}
			</Grid>

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={snackbarStatus.open}
				onClose={handleCloseSnackbar}
				autoHideDuration={1500}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarStatus.severity ? snackbarStatus.severity : "info"}
				>
					{snackbarStatus.message}
				</Alert>
			</Snackbar>

			<SnackbarStore />
			<NotificationFavicon />
		</Paper>
	);
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MemoizedCockpit = memo(Cockpit);
export default MemoizedCockpit;
