import React, { memo } from "react";
import ActionEventMessageItem from "./components/ActionEventMessageItem";
import "./MessageItem.css";
import Message from "./components/Message/index";
import MovedMessage from "./components/MovedMessage";
import NoteMessage from "./components/NoteMessage";

/**
 * Handle the correct message to display
 * @param {*} param0
 * @returns
 */
const MessageItem = ({
	message,
	setSnackbarStatus,
	msgIndex,
	handleEngagedialog,
	fromHistoric,
	feedbackDisplay,
	convHistory,
	displayForwardedMessages,
	showMessageStateInfo = true,
	showMessageActions = true
}) => {
	//NOTE: This is fixing a tempory issue 02/03/2021 of malformatted message.
	if (!message.body && !message.payload?.payload) {
		return <p></p>;
	}

	/**
	 * Handle different message states
	 */
	switch (message?.header?.state) {
		case "moved": {
			return <MovedMessage message={message} />;
		}
		default:
			break;
	}

	/**
	 * Handle different message types
	 */
	switch (message?.header?.type) {
		case "note": {
			if (displayForwardedMessages) {
				return <NoteMessage message={message} />;
			} else {
				return null;
			}
		}
		case "forwarded": {
			if (displayForwardedMessages) {
				return (
					<Message
						message={message}
						setSnackbarStatus={setSnackbarStatus}
						msgIndex={msgIndex}
						handleEngagedialog={handleEngagedialog}
						feedbackDisplay={feedbackDisplay}
						showMessageStateInfo={false}
						type="forwarded"
					/>
				);
			} else {
				return null;
			}
		}
		case "actionEvent": {
			// Display action event (conv/message events) message
			return <ActionEventMessageItem data={message}></ActionEventMessageItem>;
		}
		default: {
			return (
				<Message
					message={message}
					setSnackbarStatus={setSnackbarStatus}
					msgIndex={msgIndex}
					handleEngagedialog={handleEngagedialog}
					fromHistoric={fromHistoric}
					feedbackDisplay={feedbackDisplay}
					convHistory={convHistory}
					showMessageStateInfo={showMessageStateInfo}
					showMessageActions={showMessageActions}
				/>
			);
		}
	}
};

const MemoizedMessageItem = memo(MessageItem);
export default MemoizedMessageItem;
