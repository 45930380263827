import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Typography
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GetChannelImage from "../../../../helpers/GetChannelImage";
import cockpitActions from "../../../../redux/actions/cockpitActions";

import { formatDate, handleSpecialDisplayDate } from "../../../../helpers/utilities";
import { getConvItemSelector } from "redux/selectors/cockpitSelectors";
/**
 * Dialog show at screen to select a conversation
 * in a consrsations list to move a message into it
 */
const SelectConversationDialog = ({ handleClose, open, handleValidate }) => {
	const dispatch = useDispatch();
	const { channels } = useSelector(state => state.assistantconfig);
	const { selectedConversation, userLanguage, dateFormat } = useSelector(
		getConvItemSelector,
		shallowEqual
	);
	const [conversationList, setConversationList] = useState(null);
	const [selectedfID, setselectedfID] = useState(null);
	/**
	 * Close dialog and send selected conversation's fID
	 */
	function closeAndValidate() {
		handleClose();
		handleValidate(selectedfID);
		setselectedfID(null);
	}
	/**
	 * Close dialog
	 */
	function closeDialog() {
		handleClose();
		setselectedfID(null);
	}
	/**
	 * Load conversations
	 */
	function loadConversations() {
		function onSuccess(data) {
			setConversationList(data);
		}

		dispatch(
			cockpitActions.FetchHistory(
				selectedConversation,
				"move",
				onSuccess,
				() => {},
				() => {}
			)
		);
	}
	/**
	 * Date display
	 */
	const handleDisplayDate = (datetime, ts) => {
		if (dateFormat) {
			return handleSpecialDisplayDate(ts, dateFormat, userLanguage);
		} else {
			return formatDate(datetime);
		}
	};
	/**
	 * Load conversation when dialog is open
	 */
	useEffect(() => {
		if (open && !conversationList?.length) {
			loadConversations();
		}
	}, [open]);

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={closeDialog}>
			<DialogTitle>{i18n.t("COC.moveMsgToTargetConv")}</DialogTitle>

			<DialogContent>
				{conversationList == null ? (
					<DialogContentText>{i18n.t("COC.loadingConversations")}</DialogContentText>
				) : (
					<>
						<Typography variant="subtitle2" color="textSecondary">
							{i18n.t("COC.selectConversation")}
						</Typography>
						<List>
							{conversationList
								.filter(conversation => conversation.state !== "hidden")
								.map((conversation, index) => {
									return (
										<ListItem
											selected={conversation.fID === selectedfID}
											key={index}
											button
											onClick={() => {
												setselectedfID(conversation.fID);
											}}
										>
											<ListItemAvatar>
												<Box component="span" mr={0.5}>
													<img
														src={GetChannelImage(channels, conversation.channelCode)}
														alt="marketplace-logo"
														width="30"
														height="30"
														aria-controls="fade-menu"
														aria-haspopup="true"
														className="logo-channel"
													/>
												</Box>
											</ListItemAvatar>
											<ListItemText
												primary={conversation.subject}
												secondary={`${handleDisplayDate(
													conversation.datetime,
													conversation.ts
												)} - ${conversation.fID}`}
											/>
											<ListItemSecondaryAction>
												<ListItemText primary={i18n.t(`HISTORY.${conversation.state}`)} />
											</ListItemSecondaryAction>
										</ListItem>
									);
								})}
						</List>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary">
					Annuler
				</Button>
				<Button onClick={closeAndValidate} color="primary" disabled={selectedfID === null}>
					Déplacer
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SelectConversationDialog;
