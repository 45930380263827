import React from "react";
import lod_ from "lodash";
import { Box, Grow, ListItem, Paper, Typography } from "@material-ui/core";
import { getMessageStyle } from "./functions";
import { shallowEqual, useSelector } from "react-redux";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import { handleSpecialDisplayDate, formatDate } from "helpers/utilities";
import { defaultStyles, FileIcon } from "react-file-icon";
import { getFileExtension } from "helpers/actions/FileActions";
import { t } from "i18next";
import { getNameAgent } from "helpers/utilities";
import FileActions from "helpers/actions/FileActions";
import { mimeTypeCompute } from "helpers/actions/FileActions";

/**
 * Note mesage component,
 * Display note in cockpit
 * @param {*} param0
 * @returns
 */
const NoteMessage = ({ message }) => {
	const { userGroup } = useSelector(state => state);
	const { fontSize, cockpit, userStatus } = useSelector(getMesssageItemSelector, shallowEqual);

	const dateFormat = cockpit?.datetime_format?.format;
	const userLanguage = userStatus?.auth?.user?.language?.toLowerCase() ?? "fr";

	const noteAuthorName = getNameAgent(userGroup?.users, message.header.author);

	const { paperStyle, justifyDirection } = getMessageStyle(message);

	/**
	 * Group esclated note title
	 * @return group esclated note
	 */
	const groupEsclatedNote = () => {
		const escalationGroupName = message.header.escalationGroup?.name;

		if (!lod_.isNil(escalationGroupName)) {
			return ` ${t("COC.groupEscalated")} ${escalationGroupName}`;
		}

		return "";
	};

	/**
	 * Get the title of the note
	 * @return title
	 */
	const getTitleOfNote = () => {
		switch (message.header.subtype) {
			case "escalated":
				return `${t("COC.noteWritterEscalated")} ${noteAuthorName} ${groupEsclatedNote()}`;
			case "escalatedDone":
				return `${t("COC.noteWritterEscalatedDone")} ${noteAuthorName}`;
			default:
				return `${t("COC.noteWritter")} ${noteAuthorName}`;
		}
	};

	return (
		<ListItem data-component-name="MessageItem">
			<Grow in={true}>
				<Box width={1} display="flex" justifyContent={justifyDirection}>
					<Box display="flex" flexDirection="column" width="40%">
						<Box display="flex" alignItems="flex-end">
							<Paper elevation={3} style={paperStyle}>
								<Box
									p={1}
									style={{
										backgroundColor: "rgb(239 230 179)",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center"
									}}
								>
									<Typography variant="caption" style={{ fontSize: 11 }}>
										{getTitleOfNote()}
									</Typography>
									{!lod_.isNil(message.payload.payload.attachment) && (
										<FileActions
											attachment={{
												// Default attachment attributes
												...message.payload.payload.attachment,
												mimeType: mimeTypeCompute(message.payload.payload.attachment)
											}}
										/>
									)}
								</Box>
								<React.Fragment>
									{/* Display text for every subtype */}
									{!lod_.isNil(message.payload.payload.text) && (
										<Box
											mx={1}
											style={{
												fontSize,
												lineHeight: "1.5em"
											}}
										>
											{message.payload.payload.text}
										</Box>
									)}
									{/* Display attachment */}
									{/* Careful : here subtype is "attachement" instead of "attachment" */}
									{message.header.subtype === "attachement" && (
										<Box my={1} fontWeight="fontWeightMedium" fontStyle="italic">
											<AttachementItem attachement={message.payload.payload.attachment} />
										</Box>
									)}
								</React.Fragment>

								<Box display="flex" m={1} alignItems="center">
									<Box flexGrow={1} fontSize={11}>
										{dateFormat
											? handleSpecialDisplayDate(
													message.header?.dateCreated?.ts,
													dateFormat,
													userLanguage
												)
											: formatDate(message.header?.dateCreated?.datetime)}
									</Box>
								</Box>
							</Paper>
						</Box>
					</Box>
				</Box>
			</Grow>
		</ListItem>
	);
};

/**
 * Attachement component
 * @param {*} param0
 * @returns
 */
const AttachementItem = ({ attachement }) => {
	let attachementType = attachement.contentType || attachement.type;

	return (
		<Box
			sx={{ m: 1 }}
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			}}
		>
			{attachementType?.includes("image") ? (
				<Box
					component="img"
					alt={attachement.name}
					src={attachement.url}
					style={{
						maxWidth: "100%",
						maxHeight: "100%",
						objectFit: "contain"
					}}
				/>
			) : (
				<div className="fileIconContainer">
					<FileIcon
						extension={getFileExtension(attachement.name).replace(".", "")}
						{...defaultStyles[getFileExtension(attachement.name).replace(".", "").toLowerCase()]}
					/>
				</div>
			)}

			<Box>{attachement.name}</Box>
		</Box>
	);
};

export default NoteMessage;
