import axios from "axios";

const crmBackEndpoint = process.env.REACT_APP_CRMBACK;

/**
 * Load suggestions from CRM Back for a given collection and a given search text. See platformConfig["CRM-Back"].config.searchDataAllowedCollections for the list of available collections.
 * @param {string} assistantID - The assistant ID
 * @param {object} user - The user object
 * @param {string} searchText - The text to search
 * @param {string} collection - The collection to search in
 * @param {string} sort - The sort to apply in format "field:asc" or "field:desc"
 */
export async function loadSuggestions(
	assistantID,
	user,
	searchText,
	collection,
	sort = null,
	limit = 10,
	aggregate = null
) {
	let url = `${crmBackEndpoint}/api/v1/ressource/${collection}/items?search=${searchText}&page=1&limit=${limit}`; // TODO add sort

	if (sort) {
		url += `&sort=${sort}`;
	}

	if (aggregate) {
		url += `&aggregate=${JSON.stringify(aggregate)}`;
	}

	let result;

	try {
		const resCall = await axios.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${user.token}`,
				UserEmail: user.email,
				assistantID
			}
		});
		result = resCall.data.results;
	} catch (err) {
		//
	}
	return result || [];
}

/**
 * Load suggestions from CRM Back for a given collection and a given search text. See platformConfig["CRM-Back"].config.searchDataAllowedCollections for the list of available collections.
 * @param {string} assistantID - The assistant ID
 * @param {object} user - The user object
 * @param {string} searchText - The text to search
 * @param {string} collection - The collection to search in
 * @param {string} sort - The sort to apply in format "field:asc" or "field:desc"
 */
export async function loadSuggestionsEnrich(
	assistantID,
	user,
	searchText,
	host,
	sourceApi,
	context = {},
	path = "",
	sourceAction = "",
	limit = 10
) {
	let url = `${host}/api/v1/${path}`; // TODO add sort

	let result;

	try {
		const resCall = await axios.post(
			url,
			{
				searchText,
				limit,
				sourceApi,
				context,
				sourceAction
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${user.token}`,
					UserEmail: user.email,
					assistantID
				}
			}
		);
		result = resCall.data.results;
	} catch (err) {
		//
	}
	return result || [];
}
