import React from "react";
import { Box } from "@material-ui/core";
import NotificationImportantOutlinedIcon from "@material-ui/icons/NotificationImportantOutlined";
import { t } from "i18next";

/**
 * Message subject component
 * @param {*} param0
 * @returns
 */
export const Subject = ({ urgent, topic, justify }) => {
	return (
		<Box
			p={1}
			display="flex"
			fontWeight="fontWeightBold"
			alignItems="center"
			justifyContent={justify ? "end" : "start"}
		>
			{urgent && (
				<Box>
					<NotificationImportantOutlinedIcon />
				</Box>
			)}
			<Box style={{ marginRight: "4px" }}>{t("COC.Subject")}:</Box>
			<Box>{topic}</Box>
		</Box>
	);
};

export default Subject;
