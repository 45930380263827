import React from "react";
import { useSelector } from "react-redux";
import "../../Message/MessageItem.css";
import MessageItemCOLDPdf from "./MessageItemCOLDPdf";
import MessageItemCOLDFaibrikLight from "./MessageItemCOLDFaibrikLight";
import { Box, Typography } from "@material-ui/core";
import i18n from "i18n";

// Types from ExportData
const COLD_MANAGED_BY_TYPES = {
	FAIBRIK: "faibrik", // COLD on faibrik means it is handles with mongodb format, for file storage it uses either AWS-S3 or fileSystem - active for few clients // TODO: list it?
	FAIBRIK_LIGHT: "faibrikLight", // COLD on faibrik light is when we store only messages (no file) in a mongodb cluster - eau Annecy
	API: "API" // COLD on API means that all data and file are send via API - was for client Ulygo and should be used by none else but is reusable
};

const MessageItemCOLD = ({ convHistory }) => {
	const { COLD } = useSelector(state => state.assistantconfig);

	switch (COLD?.managedBy) {
		case COLD_MANAGED_BY_TYPES.FAIBRIK:
		case COLD_MANAGED_BY_TYPES.API: {
			return <MessageItemCOLDPdf convHistory={convHistory} />;
		}
		case COLD_MANAGED_BY_TYPES.FAIBRIK_LIGHT: {
			return <MessageItemCOLDFaibrikLight />;
		}
		default: {
			return (
				<Box>
					<Typography>{`${i18n.t("COLD.impossibleToDisplay")}`}</Typography>
				</Box>
			);
		}
	}
};

export default MessageItemCOLD;
