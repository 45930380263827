import GetChannelImage from "helpers/GetChannelImage";
import { getSentimentIcon } from "helpers/utilities";
import { getConfidenceIcon } from "helpers/utilities";
import React from "react";
import { UpdateFeedback } from "../MessageItemComponents/Feedback";
import { UpdateIntent } from "../MessageItemComponents/Intent";
const { Grid, Box, Divider } = require("@material-ui/core");

export const MessageItemHeader = ({
	message,
	selectedConversation,
	channelImage,
	channels,
	headerConv,
	fromHistoric,
	showMessageStateInfo = true,
	msgIndex,
	feedbackDisplay,
	conversation
}) => {
	const getReviewIcon = () => {
		if (selectedConversation?.context?.review?.type) {
			return (
				<div className="av-source">
					<div>{selectedConversation.context?.review?.type[0].toUpperCase()}</div>
				</div>
			);
		} else {
			return <></>;
		}
	};

	return (
		<Grid container alignItems="center">
			{getReviewIcon()}
			{(message.header.channel || channelImage) && (
				<Box component="span" mr={0.5}>
					<img
						src={GetChannelImage(
							channels,
							channelImage ? channelImage : headerConv.channel,
							headerConv.channel
						)}
						alt="Channel-logo"
						width="20"
						height="20"
						className="logo-channel"
					/>
				</Box>
			)}
			{!fromHistoric && showMessageStateInfo && (
				<>
					{(message.meta?.intent || message.understand?.topic) && (
						<React.Fragment>
							<Divider orientation="vertical" flexItem />
							<Box component="span" marginTop="5px" paddingLeft="5px" mx={0.5}>
								{message.meta?.intentScore
									? getConfidenceIcon(message.meta.intentScore)
									: getConfidenceIcon(message.understand?.topicConfidenceLevel)}
							</Box>
							<Box component="span" mx={0.5}>
								<UpdateIntent message={message} msgIndex={msgIndex} />
							</Box>

							{/* ThumbDown && ThumbUP Icon to send feedback
							 <UpdateFeedback
								element={"intent"}
								feedbackDisplay={feedbackDisplay}
								message={message}
								msgIndex={msgIndex}
								conversation={conversation}
							/> */}
						</React.Fragment>
					)}
					{(message.meta?.sentiment || message.understand?.sentiment) && (
						<React.Fragment>
							<Divider orientation="vertical" flexItem />
							<Box
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}
								component="span"
								mx={0.5}
							>
								{message.meta?.sentiment
									? getSentimentIcon(message.meta.sentiment)
									: getSentimentIcon(
											message.understand?.sentiment.label || message.understand?.sentiment
										)}
							</Box>
							{/* ThumbDown && ThumbUP Icon to send feedback 
							<UpdateFeedback
								element={"sentiment"}
								feedbackDisplay={feedbackDisplay}
								message={message}
								msgIndex={msgIndex}
								conversation={conversation}
							/> */}
						</React.Fragment>
					)}
				</>
			)}
		</Grid>
	);
};
