/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Typography
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";

/**
 * Form specific: Build input with type and options given
 */
function FormModerationAvis({ action, actionObject, handleClose, handleSubmit }) {
	const [open, setOpen] = React.useState(true);
	const [openFormReport, setOpenFormReport] = React.useState(false);
	const [alreadyReport, setAlreadyReport] = React.useState(false);
	const [comment, setComment] = useState("");
	const [reason, setReason] = useState("");
	const [listOfReason, setListOfReason] = useState(actionObject?.config.reasons ?? []);

	const { selectedConversation } = useSelector(state => state.cockpit);

	useEffect(() => {
		if (
			selectedConversation?.header?.subtype === "reviewService" &&
			!lod_.isNil(actionObject?.config?.reasonsReview) &&
			!lod_.isEmpty(actionObject?.config?.reasonsReview)
		) {
			setListOfReason(actionObject?.config?.reasonsReview);
		} else if (
			selectedConversation?.header?.subtype === "reviewProduct" &&
			!lod_.isNil(actionObject?.config?.reasonsProduct) &&
			!lod_.isEmpty(actionObject?.config?.reasonsProduct)
		) {
			setListOfReason(actionObject?.config?.reasonsProduct);
		} else {
			setListOfReason([]);
		}

		const checkMessage = selectedConversation?.messages.find(
			message => message?.header?.source !== "contact"
		);
		const checkState = selectedConversation?.messages.find(
			message => message?.header?.state === "ADD_REPORT_SUCCESS"
		);

		if (!lod_.isNil(checkMessage) && lod_.isNil(checkState)) {
			setOpenFormReport(true);
		} else {
			setOpenFormReport(false);
		}

		if (!lod_.isNil(checkState)) {
			setAlreadyReport(true);
		} else {
			setAlreadyReport(false);
		}
	}, []);

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"sm"}
				open={open}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{`Signalement de l'avis`}</DialogTitle>
				<DialogContent dividers>
					{openFormReport === false ? (
						<Box style={{ display: "flex", justifyContent: "center" }}>
							<Typography>
								{alreadyReport
									? `L'avis à déja été signalé`
									: `Veuillez répondre à l'avis pour effectuer un signalement`}
							</Typography>
						</Box>
					) : (
						<Box>
							<Box paddingBottom={2}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="select-label">Motif du signalement</InputLabel>
									<Select
										labelId="select-label"
										id="select"
										label="Motif du signalement"
										value={reason}
										onChange={e => setReason(e.target.value)}
									>
										{listOfReason
											.sort((a, b) => a?.label.localeCompare(b?.label))
											.map((code, index) => (
												<MenuItem key={index} value={code.code}>
													- {code.label}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Box>

							<Box>
								<TextField
									fullWidth
									minRows={6}
									maxRows={6}
									multiline
									id="standard-required"
									name={`orderTest.other.agentComments`}
									label={i18n.t("OrderForm.agentComments")}
									variant="outlined"
									value={comment}
									onChange={e => setComment(e.target.value)}
								/>
							</Box>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
						{i18n.t("CD.cancel")}
					</Button>
					<Button
						onClick={() =>
							handleSubmit(
								{ comment, reason, review: selectedConversation?.context?.review },
								action
							)
						}
						style={{ margin: "2%" }}
						color="primary"
						disabled={lod_.isEmpty(comment) || lod_.isEmpty(reason)}
					>
						{i18n.t("CD.validate")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default FormModerationAvis;
