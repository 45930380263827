/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import { setGroupOpenDialog } from "redux/reducers/filtersReducers";
import { setFormSelected } from "redux/reducers/filtersReducers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { Chip, Grid } from "@mui/material";
import DoneIcon from "@material-ui/icons/Done";
import { TagsList } from "components/Tags/TagsList";
import C from "constants/cockpit.js";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Typography } from "@material-ui/core";
/**
 *
 */
export default ({ role }) => {
	const dispatch = useDispatch();
	const { convGroupFilter, isCold } = useSelector(state => state.cockpit);
	const classes = useStyles();
	let groupSet = convGroupFilter.length !== 0;

	return !isCold && (role === "agent" || role === "supervisor" || role === "soloUser") ? (
		<Tooltip title={i18n.t("COC.SKILLS.title")} placement="top">
			<Box className={classes.filterButton} mt={1}>
				<ToggleButtonGroup exclusive size="small">
					<ToggleButton
						value={true}
						fontSize="small"
						onClick={() => {
							dispatch(setGroupOpenDialog(true));
						}}
						style={groupSet ? { color: "black", background: "#0000001f" } : {}}
					>
						<GroupWorkIcon fontSize="small" />
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
		</Tooltip>
	) : (
		<></>
	);
};

/**
 *
 * @returns
 */
export function GroupFilterDialog() {
	const { groups } = useSelector(state => state.userGroup);
	const { convGroupFilter } = useSelector(state => state.cockpit);
	const { groupOpenDialog } = useSelector(state => state.filters);
	const dispatch = useDispatch();
	const [selected, setSelected] = useState([]);

	const isAllGroupsChecked = selected.length === groups.length;
	/**
	 *
	 */
	useEffect(() => {
		if (convGroupFilter.length > 0) setSelected(convGroupFilter);
		else setSelected([]);
	}, [groups, convGroupFilter]);

	/**
	 *
	 */
	const handleCloseGroupFilterDialog = () => {
		dispatch(setGroupOpenDialog(false));
	};

	/**
	 *
	 */
	const handleSaveFilters = clear => {
		dispatch({
			type: C.SET_CONV_GROUP_FILTER,
			payload: clear ? [] : selected
		});
		handleCloseGroupFilterDialog();
	};

	/**
	 *
	 */
	const handleDisableFilters = () => {
		setSelected([]);
		handleSaveFilters(true);
	};

	/**
	 *
	 * @param {*} value
	 */
	const toggleSelectAll = value => {
		if (isAllGroupsChecked) {
			setSelected([]);
		} else {
			setSelected(groups);
		}
	};

	const defaultGroups = groups.filter(group => !group.isEscalation);
	const escalationGroups = groups.filter(group => group.isEscalation);

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={groupOpenDialog}
			onClose={() => handleCloseGroupFilterDialog()}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				{i18n.t("COC.SKILLS.title")}{" "}
				<span style={{ float: "right" }}>
					{/* Select all toggle */}
					<Chip
						label={isAllGroupsChecked ? i18n.t("COC.deselectAll") : i18n.t("COC.selectAll")}
						color={isAllGroupsChecked ? "default" : "primary"}
						deleteIcon={isAllGroupsChecked ? <HighlightOffIcon /> : <DoneIcon />}
						clickable={true}
						onClick={() => {
							toggleSelectAll();
						}}
						onDelete={() => {
							toggleSelectAll();
						}}
					/>
				</span>
			</DialogTitle>
			<DialogContent dividers>
				<Grid container>
					<Typography variant="body2" fontSize="small" style={{ fontWeight: "bold" }}>
						{i18n.t("COC.SKILLS.skills")}
					</Typography>
					<TagsList
						list={defaultGroups.sort((a, b) => a.name.localeCompare(b.name))}
						labelField={"name"}
						titleField={"description"}
						valueField={"_id"}
						isForm={true}
						selected={selected}
						setSelected={setSelected}
					/>
				</Grid>

				{escalationGroups.length > 0 && (
					<Grid container sx={{ mt: 5 }}>
						<Typography variant="body2" fontSize="small" style={{ fontWeight: "bold" }}>
							{i18n.t("COC.SKILLS.escalation")}
						</Typography>
						<TagsList
							list={escalationGroups.sort((a, b) => a.name.localeCompare(b.name))}
							labelField={"name"}
							titleField={"description"}
							valueField={"_id"}
							isForm={true}
							selected={selected}
							setSelected={setSelected}
						/>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleDisableFilters()} color="primary">
					{`${i18n.t("COC.DisableFilter")}`}
				</Button>
				<Button onClick={() => handleCloseGroupFilterDialog()} color="secondary">
					{`${i18n.t("COC.Cancel")}`}
				</Button>
				<Button
					disabled={selected.length === 0}
					onClick={() => handleSaveFilters()}
					color="primary"
				>
					{`${i18n.t("COC.ApplyFilters")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
